import React, { useEffect } from 'react';
import { fade } from '../helpers/helpers'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

import Top from '../components/home/top';
import Intro from '../components/home/intro';
import Columns from '../components/home/columns';
import Project from '../components/home/project';
import Clients from '../components/home/clients';
import Footer from '../components/footer';

import { useHomeQuery } from "../components/hooks/useHomeQuery"

import logo from '../images/static/logo-800.gif';


// markup
const Home = ({transitionStatus}) => {
  const { scroll } = useLocomotiveScroll()

  useEffect(() => {
    fade('main');
  }, []);

  useEffect(() => {
    if (scroll && transitionStatus === 'entering') {
      scroll.scrollTo(0, { duration: 0, easing: 0, disableLerp: true });
      scroll.update();
    }

  }, [transitionStatus]);

  return (
    <>
      <Top />
      <Intro />
      <Columns />
      <Project />
      <Clients />
      <Footer/>
    </>
  )
}

export default Home


export function Head() {
  const url = 'https://madebymade.pl';
  const {
    home: { acfHome: data }
  } = useHomeQuery();
  return (
    <>
      <title>MADE - Design. Brand. Technology.</title>
      <meta name="description" content={data?.text ? data?.text : "We'd love to hear from you. hello@madebymade.pl" } />
      <meta name="og:description" content={data?.text ? data?.text : "We'd love to hear from you. hello@madebymade.pl" } />
      <meta name="og:image" content={logo} />
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={url} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="madebymade.pl" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content="MADE" />
      <meta name="twitter:description" content={data?.text ? data?.text : "We'd love to hear from you. hello@madebymade.pl"} />
      <meta name="twitter:image" content={logo} />
    </>
  )
}