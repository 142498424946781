import { useStaticQuery, graphql } from "gatsby"

export const useHomeQuery = () => {
  const data = useStaticQuery(graphql`
    query homeQuery {
      home: wpPage(uri: {eq: "/"}) {
        acfHome {
          text
          clientsTitle
          columnsTitle
          more
          projectLink
          projectTitle
          projectImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)      
              }    
            }
          }
          columns {
            text
          }
          clients {
            title
            clients {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)      
                }    
              }
            }
          }
        }
      }
    }
  `)

  return data;
}