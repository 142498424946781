import React from 'react';
import { Link } from 'gatsby';
import { useHomeQuery } from "../../components/hooks/useHomeQuery"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Project = () => {
  const {
    home: { acfHome: data }
  } = useHomeQuery()
   const img = getImage(data?.projectImg?.localFile);
   const alt = data?.projectImg?.altText;

  return (
    <section data-scroll-section>
      {data.projectImg && (
        <div className="container-fluid pt-100 pb-100">
          <div className="row">
            <div className="col-12">
              {data.projectTitle && (
                <div data-scroll>
                  <div className="mb-6 anim" style={{ '--delay': .2 }} dangerouslySetInnerHTML={{__html: data.projectTitle}}></div>
                </div>
              )}
              <Link to={data.projectLink} data-scroll className="anim-img portfolio-item portfolio-item-full">
                <GatsbyImage image={img} alt={alt} as="span" style={{ '--delay': .2 }} />
              </Link>
              <div className="col-12 hover-underline mt-6" data-scroll>
                <Link className="anim" style={{ '--delay': .3 }} aria-label="Portfolio" to="/portfolio">see more</Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Project
