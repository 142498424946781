import React from 'react';
import { Link } from 'gatsby';
import { useHomeQuery } from "../../components/hooks/useHomeQuery"

const Columns = () => {
  const {
    home: { acfHome: data }
  } = useHomeQuery()


  return (
    <section data-scroll-section>
      <div className="container-fluid pt-100 pb-100">
        <div className="row">
          <div className="col-12" data-scroll>
            {data.columnsTitle && (
              <h2 className="pb-100 mb-5 anim" dangerouslySetInnerHTML={{__html: data.columnsTitle}}></h2>
            )}
          </div>
          {data.columns && data.columns.map((column, i) => (
            <div key={i} className="col-lg-3 paragraph mb-10 pb-8" data-scroll>
              <div className="anim" style={{ '--delay': i*2/10 }} dangerouslySetInnerHTML={{__html: column.text}} />
            </div>
          ))}

          <div className="col-1 offset-lg-3 col-lg-1 text-nowrap fs-15 more-prev mb-2 mb-lg-0" data-scroll><span className="anim" style={{ '--delay': .1 }}>{data.more}</span></div>
          <div className="col-3 offset-8 offset-lg-2 col-lg-4 fs-15 hover-rm-underline more" data-scroll>
            <Link to="/portfolio" aria-label="Portfolio" className="anim" style={{ '--delay': .3 }}>see more</Link>
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default Columns
