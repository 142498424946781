import React from 'react';
import { useHomeQuery } from "../../components/hooks/useHomeQuery"

const Intro = () => {
  const {
    home: { acfHome: data }
  } = useHomeQuery()

  return (
    <section id="about" data-scroll-section>
      <div className="container-fluid pt-100 pb-100" id="aboutus">
        <div className="row">
          <div className="col-lg-9" data-scroll>
            {data.text && (
              <h1 className="anim" dangerouslySetInnerHTML={{__html: data.text}}></h1>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
