import React from 'react';
import { useHomeQuery } from "../../components/hooks/useHomeQuery"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Clients = () => {
  const {
    home: { acfHome: data }
  } = useHomeQuery()
   const rows = data.clients;

  return (
    <section data-scroll-section>
      {rows && (
        <div className="container-fluid pt-100">
          <div className="row">
            <div className="col-12" data-scroll>
              {data.clientsTitle && (
                <h2 className="mb-10 anim" dangerouslySetInnerHTML={{__html: data.clientsTitle}}></h2>
              )}
            </div>
          </div>
          {rows.map((row, i) => (
            <div className="row align-items-center logos" key={i*i}  data-scroll>
              {row.title && (
                <div className="mb-6 col-12 mt-10 pt-7 anim" dangerouslySetInnerHTML={{__html: row.title}}></div>
              )}

              {row.clients && row.clients.map((client, j) => (
                <div className="col-6 col-sm-4 col-md-2 anim" style={{ '--delay': j/10 }} key={client.id}>
                  <GatsbyImage image={getImage(client?.localFile)} alt={client?.altText} as="span" />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </section>
  )
}

export default Clients
