import React from 'react';
//import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

import logo from '../../images/static/logo-800.gif';

const Top = () => {
  return (
    <section className="hero" data-scroll-section>
      <div className="container-fluid">
        <div className="row py-10">
          <div className="col-12 d-flex align-items-center anim-img" data-scroll>
            <img src={logo} width={800} height={800} alt="MADE by MADE" className="mx-auto" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Top
